@import "../styles/_colors";

.navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: fixed;
  text-align: right;
  z-index: 3;
  a {
    margin-bottom: 36px;
    &:hover {
      text-decoration: underline;
    }
  }
}

// Media Queries
@media screen and (max-width: 768px) {
  .navigation {
    z-index: 100;
    &.col-2 {
      flex-direction: row;
      padding: 0;
      width: 100%;
    }
    height: auto;
    justify-content: flex-start;
    overflow: scroll;
    position: fixed;
    text-align: left;
    z-index: 100;
    &::-webkit-scrollbar {
        width: 0px;  /* remove scrollbar space */
        background: transparent;  /* optional: just make scrollbar invisible */
    }
    a {
      background: $pink;
      color: $white;
      margin-bottom: 0;
      padding: 16px 24px;
      &.active {
        background: $white;
        color: $pink;
      }
    }
  }
}
