@import "_colors";
@import url('https://fonts.googleapis.com/css?family=PT+Mono|PT+Sans:400,700');

h1,
h1 a {
  color: $gray;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 1.15;
  strong {
    color: $off-black;
  }
  a {
    color: $pink;
    display: inline;
    &:hover {
      text-decoration-color: $pink;
      text-decoration: underline;
    }
  }
}

h2 {
  color: $off-black;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 33px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 8px;
}

// For numbers only
h3 {
  color: $pink;
  font-family: "PT Mono", monospace;
  font-size: 28px;
}

h4 {
  color: $gray;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 20px;
}

li, p {
  color: $off-black;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.3;
}

a,
h6 {
  color: $off-black;
  display: block;
  font-family: "PT Mono", monospace;
  font-size: 17px;
  margin-bottom: 8px;
  text-decoration: none;
  &.active {
    color: $pink;
  }
}

// Generates bolding
@for $i from 1 through 6 {
  h#{$i} strong {
    font-weight: 700;
  }
}

*::selection {
  background: $yellow; /* WebKit/Blink Browsers */
}
*::-moz-selection {
  background: $yellow; /* Gecko Browsers */
}

@media screen and (max-width: 1536px) {
  h1,
  h1 strong {
    font-size: 48px;
  }
}

@media screen and (max-width: 1296px) {
  h1,
  h1 strong,
  h1 a {
    font-size: 45px;
    line-height: 1.0;
  }
}

@media screen and (max-width: 600px) {
  h1, h1 a, h1 strong {
    font-size: 42px;
    line-height: 1.05;
  }
  h2 {
    font-size: 28px;
    line-height: 1.1;
  }
  h3 {
    font-size: 28px;
  }
  a,
  h6 {
    font-size: 14px;
  }
  li, p {
    font-size: 17px;
  }
}
