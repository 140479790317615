@import "styles/_base";

.header {
  margin-bottom: $medium-spacer;
}

.header-image.does-not-exist {
  background-image: url("assets/images/headers/does-not-exist.jpg");
}

ul.text-list {
  margin-top: $small-spacer;
  li {
    color: $dark-gray;
    font-size: 20px;
    list-style: square;
    margin-bottom: $small-spacer;
    strong {
      font-weight: 600;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.does-not-exist.projects-wrapper {
  // margin-top: -220px;
}
