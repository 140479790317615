@import "../styles/_base";

.project-header {
  width: 100%;
}

.project-title {
  margin-top: 16px;
  margin-bottom: 8px;
}

.project-description {
  color: $gray;
  font-weight: 300;
  margin-bottom: $medium-spacer;
}

.text-group {
  display: flex;
  flex-direction: row;
}

.large-spacer-top {
  margin-top: $large-spacer;
}

.medium-spacer-bottom {
  margin-bottom: $medium-spacer;
}

.medium-spacer-top {
  margin-top: $medium-spacer;
}

.small-spacer-bottom {
  margin-bottom: $small-spacer;
}

.project-image {
  // border-radius: $border-radius;
  width: 100%;
  &.vertical {
    width: 60%;
  }
}

p.large-paragraph,
p.large-paragraph a {
  color: $dark-gray;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 1.4;
  strong {
    font-weight: 600;
  }
  a {
    color: $pink;
    display: inline;
    &:hover {
      text-decoration: underline;
    }
  }
  &.quote {
    border-left: 2px solid $pink;
    color: $gray;
    font-size: 18px;
    font-style: italic;
    padding: 0 $medium-spacer;
  }
}

h3.project-subheading {
  color: $off-black;
  font-family: "PT Sans", Helvetica, Arial;
  font-size: 24px;
  line-height: 1.2;
  strong {
    font-weight: 700;
    font-style: normal;
  }
}

p.reflections {
  color: $pink;
  font-family: "PT Mono", monospace;
  font-size: 13px;
  line-height: 1.4;
}

ol.text-list,
ul.text-list {
  li {
    color: $dark-gray;
    font-size: 20px;
    margin-bottom: $small-spacer;
    strong {
      font-weight: 600;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

ul.text-list {
  list-style: square;
}

ol.text-list {
  list-style: decimal;
}

.next-shelf {
  align-items: center;
  display: flex;
  margin-top: 90px;
  .right-text {
    text-align: right;
  }
  .shelf-project-link {
    align-items: center;
    display: flex;
    &:hover {
      cursor: pointer;
      h2 {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: $pink;
      }
    }
  }
}

.video-wrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  #project-area {
    .col-7 {
      width: 100vw;
    }
    .col-5 {
      width: 80vw;
    }
    .col-2 {
      width: 20vw;
    }
  }
  .next-shelf {
    align-items: flex-start;
    flex-direction: row;
    .prev-project {
      text-align: left;
    }
    .next-project {
      .shelf-project-link {
        flex-direction: column-reverse;
      }
      text-align: right;
    }
    .prev-project .shelf-project-link {
      flex-direction: column;
    }
    .project,
    .next-shelf .shelf-project-link {
      align-items: flex-start;
      width: 50vw;
      .col-1, .col-3 {
        width: 50vw;
      }
      h2 {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  #project-area {
    .col-5 {
      width: 100vw;
    }
    .col-2 {
      width: 20vw;
    }
  }
  .project-header,
  .project-image {
    border-radius: 0;
    padding: 0;
  }
  .project-header {
    margin-top: $medium-spacer;
  }
  .project-header,
  .project-image {
    border-radius: 0;
    margin-left: -16px;
    width: 100vw;
  }
  .text-group {
    flex-direction: column;
  }
  .reflections {
    display: none;
  }
  p.large-paragraph,
  p.large-paragraph a,
  p.large-paragraph.quote {
    font-size: 17px;
  }
  ul.text-list {
    li { font-size: 17px; }
    padding: 0 32px;
  }
}
