@import "_reset";
@import "_colors";
@import "_typography";
@import "_grid";

$border-radius: 8px;

body {
  background: $off-white;
}

html {
  box-sizing: border-box;
}

.header {
  align-items: center;
  display: flex;
}

.image {
  border-radius: $border-radius;
}
