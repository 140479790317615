@import "styles/_base";

.header {
  margin-bottom: $medium-spacer;
}

.header-image.about {
  background-image: url("/images/elizabeth-horizontal.jpg");
}

.bio-text h1 {
  color: $off-black;
}

.about-group {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.social-media-link {
  color: $pink;
  text-align: right;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.about-module {
  @extend .nested;
  @extend .col-6;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin-top: $large-spacer;
  h2 {
    span.about-module-text {
      // text-decoration: underline;
      // text-decoration-color: $pink;
    }
  }
  p {
    color: $gray;
    &.about-description a {
      color: $pink;
      display: inline;
      font-family: "PT Sans", Helvetica, Arial, sans-serif;
      font-size: 18px;
      line-height: 1.3;
      &:hover {
          text-decoration: underline;
      }
    }
  }
}

// Mobile screens
@media screen and (max-width: 768px) {
  .about-group.col-8.nested {
    display: flex;
    flex-direction: column-reverse;
  }
  .social-media.col-1 {
    justify-content: space-around;
    display: flex;
    flex-direction: row;
  }
  .bio-text {
    margin-top: $small-spacer;
  }
}
