@import "styles/_base";

.header {
  @extend .col-9;
  @extend .offset-3;
  @extend .nested;
  z-index: 3;
}

.header-image-wrapper {
  @extend .col-4;
  @extend .offset-neg-1;
}

.header-text-wrapper {
  @extend .col-4;
  z-index: 4;
}

.header-image {
  background-image: url("/images/elizabeth-portrait.jpeg");
  background-color: $white;
  background-position: center;
  background-size: cover;
  border-radius: $border-radius;
  height: 550px;
  &:hover {
    // background-image: url("/images/elizabeth-horizontal-hover.jpg");
    cursor: url("assets/crown-cursor.png"), pointer;
  }
}

.header-text {
  // @extend .col-4;
  z-index: 4;
  margin-bottom: $small-spacer;
  .highlighter {
      // background: $off-white;
  }
}

.title-caption {
  color: $dark-gray;
}

.pink-area {
  background: $light-pink;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: fixed;
  width: 100vw;
  z-index: -1;
}

.projects-wrapper {
  background: $light-pink;
  display: flex;
  margin-top: -115px;
  margin-bottom: 90px;
  padding: 90px 0;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.project-number {
  text-align: right;
}

.project {
  box-sizing: border-box;
  display: flex;
  p {
    color: $gray;
  }
  .project-thumb {
    // border: 4px solid transparent;
    background: $white;
    border-radius: $border-radius;
    height: auto;
    margin-bottom: $small-spacer;
    width: 100%;
  }
  .image {
    background-color: $white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    // border: 4px solid $light-pink;
    border-radius: $border-radius;
    height: 200px;
    margin-bottom: $small-spacer;
  }
  .project-link {
    position: relative;
    &.fixed  {
      flex-direction: row;
      display: flex;
      &:hover {
        &::after {
          content: "";
        }
      }
    }
    &.flipped:hover {
      &::after {
        content: url(assets/sparkle-smaller.gif);
        height: 126px;
        width: 100px;
        display: block;
        position: absolute;
        top: -20px;
        left: -20px;
      }
    }
    &:hover {
      cursor: pointer;
      h2 {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: $pink;
        span.highlight {
          background-color: $pink;
          color: $light-pink;
        }
      }
      &::after {
        content: url(assets/sparkle-smaller.gif);
        height: 126px;
        width: 100px;
        display: block;
        position: absolute;
        top: -20px;
        right: -20px;
      }
    }
  }
}

.project-fixed {
  display: flex;
}

.project-thumb-fixed {
  height: 210px;
  margin-bottom: $small-spacer;
  .project-thumb {
    height: 210px;
    object-fit: cover;
  }
}

#project-2 {
  flex-direction: column;
  margin-top: $xxlarge-spacer;
  padding-left: 0;
  padding-right: 0;
  margin-left: -4px;
  .project-number {
    text-align: left;
    margin-bottom: $small-spacer;
  }
  .image {
    height: 275px;
  }
}

#project-3 {
  flex-direction: column;
  .project-link {
    display: block;
  }
  .project-number {
    text-align: left;
    margin-bottom: $small-spacer;
  }
  .image {
    height: 280px;
  }
  margin-top: -$medium-spacer;
}

#project-4 {
  display: flex;
  margin-top: 200px;
  .project-link {
    align-items: center;
    flex-direction: row-reverse;
  }
  .project-number {
    text-align: left;
  }
  .image {
    height: 360px;
    margin-bottom: 0;
  }
}

#project-5 {
  margin-top: $medium-spacer;
}

#project-6 {
  flex-direction: column;
  .project-link {
    display: block;
  }
  .project-number {
    margin-bottom: $small-spacer;
  }
  .image {
    height: 280px;
  }
  margin-top: $xxlarge-spacer;
}

@media screen and (max-width: 1536px) {
  .header-image {
    height: 500px;
  }
  .projects-wrapper {
    margin-top: -130px;
  }
}

@media screen and (max-width: 1200px) {
  .header-image {
    height: 450px;
  }
  #project-4 {
    margin-left: 10vw;
    margin-top: 60px;
  }
  .projects-wrapper {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .header {
    align-items: center;
    display: flex;
    margin-top: 80px;
    justify-content: center;
    width: 100vw;
  }
  .header-image-wrapper {
    width: 60vw;
  }
  .header-image {
    height: 400px;
  }
  .col-3.title-caption {
    font-size: 15px;
    width: 40vw;
  }
  .col-4.header-text {
    width: 60vw;
  }
  .projects {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}


@media screen and (max-width: 600px) {
  .header {
    flex-direction: column-reverse;
    margin-top: 0;
  }
  .header-image {
    border-radius: 0;
    height: 450px;
    margin-left: -16px;
    margin-top: 36px;
    margin-bottom: $medium-spacer;
    width: 100vw;
  }
  .header-image-wrapper {
    width: 100vw;
  }
  .header-text-wrapper {
    margin-bottom: $medium-spacer;
  }
  .header-text {
    text-align: left;
    width: 100vw;
  }

  .header-text,
  .title-caption {
    width: 100vw !important;
  }

  .project .project-link {
    flex-direction: column;
    &.fixed {
      flex-direction: column;
    }
  }
  .project-number,
  #project-2 .project-number,
  #project-3 .project-number,
  #project-4 .project-number,
  #project-5 .project-number,
  #project-6 .project-number {
    text-align: left;
    margin-bottom: $xxsmall-spacer;
  }
  #project-1,
  #project-2,
  #project-3,
  #project-4,
  #project-5,
  #project-6,
  .project {
    margin: $small-spacer 0;
    .col-3,
    .col-4 {
      width: 100vw;
      .image {
        height: 200px;
      }
    }
  }
  .projects-wrapper {
    border-radius: 0;
    margin-bottom: 0;
    margin-top: -400px;
    padding: 350px 0 60px 0;
  }
}
